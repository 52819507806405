<template>
  <div>
    <!--背书信息-->
    <el-descriptions title="背书信息" :column="2" class="mt30">
      <el-descriptions-item label="被背书人">{{baseInfo.compName}}</el-descriptions-item>
      <el-descriptions-item label="被背书人账号">{{baseInfo.acctNo}}</el-descriptions-item>
      <el-descriptions-item label="开户行">{{baseInfo.acctBankName}}</el-descriptions-item>
      <el-descriptions-item label="开户行行号">{{baseInfo.acctBankNo}}</el-descriptions-item>
      <el-descriptions-item label="背书金额">{{baseInfo.packageSum}}</el-descriptions-item>
      <el-descriptions-item label="申请日期">{{baseInfo.applyDate}}</el-descriptions-item>
      <el-descriptions-item label="转让标记">{{baseInfo.transferFlag}}</el-descriptions-item>
      <el-descriptions-item label="备注">{{baseInfo.remark}}</el-descriptions-item>
    </el-descriptions>
    <!--票据基本信息-->
    <div class="title mt30">
      <h3>票据基本信息</h3>
      <el-button type="primary" @click="handleFaceView()">票面预览</el-button>
      <el-button type="primary" @click="handleFaceDownload()">票面下载</el-button>
    </div>
    <el-table :data="superInfoList" border class="mt20">
      <el-table-column prop="packageNo" label="票据包号"></el-table-column>
      <el-table-column prop="packageSum" label="票据包金额"></el-table-column>
      <el-table-column prop="acceptor" label="承兑人"></el-table-column>
      <el-table-column prop="expireDate" label="票据到期日"></el-table-column>
      <el-table-column prop="ticketRange" label="子票区间"></el-table-column>
    </el-table>
    <!--原背书申请操作编号-->
    <h3 class="mt30">原背书申请操作编号</h3>
    <p class="mt10">操作编号： <span class="bssOperNo">{{bssOperNo}}</span></p>
    <div class="btn">
      <el-button @click="handleBack">返回</el-button>
      <el-button type="primary" @click="handleSave">确认提交</el-button>
    </div>
    <!--短信验证-->
    <el-dialog
            title="短信验证"
            :visible.sync="messageDialogVisible"
            :close-on-click-modal="false"
            width="40%"
            @close="messageDialogClosed">
      <el-form :model="messageForm"
               :rules="messageFormRules"
               ref="messageFormRef"
               class="message-form">
        <el-form-item label="操作员手机号" prop="operatorMobile">
          {{mobile}}
        </el-form-item>
        <el-form-item label="短信验证码" prop="verifyCode">
          <el-input v-model="messageForm.verifyCode" class="verify-code"></el-input>
          <DelayButton />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="handleSubmit">提交</el-button>
		    <el-button @click="messageDialogVisible = false">关闭</el-button>
		  </span>
    </el-dialog>
  </div>
</template>

<script>
  /*倒计时按钮*/
  import DelayButton from "@/views/Ticket/childrenCpn/DelayButton";
  export default {
    name: "EndorseRecallOne",
    mounted() {
      // 列表带过来的ticketBizId
      this.ticketBizId = this.$route.query.ticketBizId
      // 获取数据
      this.getInfo()
    },
    components: {
      DelayButton
    },
    data(){
      return {
        ticketBizId: '', // 业务记录id
        bssOperNo: '', // 背书申请的操作编号
        superInfoList: [], // 票据基本信息
        baseInfo: {}, // 背书信息
        handlerNodeCode: '', // 节点状态code -- 用于短信验证
        handlerTypeCode: '', // 操作类型code	-- 用户短信验证
        mobile: '', //手机号
        messageDialogVisible: false, // 控制短信弹框的显示与隐藏
        // 短信验证表单数据
        messageForm: {
          verifyCode: ''
        },
        // 短信验证表单验证规则
        messageFormRules:{
          verifyCode: [
            { required: true, message: '请输入验证码', trigger: 'blur' }
          ]
        },
      }
    },
    methods: {
      // 获取数据
      async getInfo(){
        const {data: res} = await this.$auth.get(`/cpiaoju-ticket/bs/manage/cancel/${this.ticketBizId}`);
        console.log(res)
        if(res.code !== 200) return this.$message.error(res.msg);
        this.handlerNodeCode = res.data.handlerNodeCode;
        this.handlerTypeCode = res.data.handlerTypeCode;
        this.bssOperNo = res.data.bssOperNo;
        this.superInfoList = res.data.superInfoList;
        this.baseInfo = res.data.baseInfo
      },
      // 票面预览
      async handleFaceView(){
        let params = {
          paperBagNo: this.superInfoList[0].packageNo,
          section: this.superInfoList[0].ticketRange
        }
        const res = await this.$auth.post('/cpiaoju-ticket/common/ticketFaceView', this.$qs.stringify(params), {responseType: 'blob'});
        if (res.data.type === "application/pdf"){
          const blob = res.data;
          let link = document.createElement('a');
          link.setAttribute('target', '_blank');
          link.href = window.URL.createObjectURL(blob);
          link.click()
          URL.revokeObjectURL(link.href);
        }else{
          return this.$message.error('暂无票面信息')
        }
      },
      // 票面下载
      async handleFaceDownload(){
        let params = {
          paperBagNo: this.superInfoList[0].packageNo,
          section: this.superInfoList[0].ticketRange
        }
        const res = await this.$auth.post('/cpiaoju-ticket/common/ticketFaceDownload', this.$qs.stringify(params), {responseType: 'blob'});
        console.log(res)
        if (res.headers['content-disposition']){
          const blob = res.data;
          const fileName = window.decodeURI(res.headers['content-disposition'].split(';')[1].split('=')[1]); //这是下载的关键
          let link = document.createElement('a');
          let url = URL.createObjectURL(blob);
          link.href = url;
          link.download = fileName
          console.log(link)
          link.click()
          URL.revokeObjectURL(url)
        }else{
          return this.$message.error('暂无票面信息')
        }
      },
      // 短信验证关闭
      messageDialogClosed(){
        this.$refs.messageFormRef.resetFields()
      },
      // 返回
      handleBack(){
        this.$router.push({name: 'endorseRecall'})
      },
      //下一步
      async handleSave(){
        //获取手机号
        const {data: res} = await this.$auth.get("/cpiaoju-ticket/common/mobile");
        if (res.code !== 200) return this.$message.error(res.msg);
        this.mobile = res.data.mobile
        this.messageDialogVisible = true
      },
      //提交
      handleSubmit(){
        this.$refs.messageFormRef.validate(async valid => {
          if(!valid) return;
          // 短信验证参数
          let verifyParams = {
            ticketId: this.ticketBizId,
            handlerTypeCode: this.handlerTypeCode,
            handlerNodeCode: this.handlerNodeCode,
            smsCode: this.messageForm.verifyCode
          };
          // 短信验证
          const {data: res} = await this.$auth.post("/cpiaoju-ticket/workbench/task/todo/verifyCode", this.$qs.stringify(verifyParams));
          if(res.code === 200){
            const {data: result} = await this.$auth.post('/cpiaoju-ticket/bs/manage/cancel/submit', this.$qs.stringify({ticketBizId: this.ticketBizId}))
            if (result.code !== 200) return this.$message.error(result.msg)
            this.messageDialogVisible = false
            this.$emit('handleSubmit', {activeStep: 2, text: result.data.text, handlerNo: result.data.handlerNo})
          }else {
            this.$message.error(res.msg)
            this.messageDialogVisible = true
          }
        })
      }
    }
  }
</script>

<style lang="less" scoped>
  .title{
    h3{
      display: inline-block;
      margin-right: 10px;
    }
  }
  .btn{
    text-align: center;
    margin-top: 50px;
    .el-button{
      min-width: 120px;
    }
  }
  /deep/ .verify-code{
    width: 220px;
  }
  .bssOperNo{
    color: #c70009;
    font-weight: bold;
  }
</style>