<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>背书管理</el-breadcrumb-item>
      <el-breadcrumb-item>背书撤销申请</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <!--步骤条-->
      <el-steps :active="activeStep"
                process-status="wait"
                finish-status="finish"
                align-center
                class="mt30">
        <el-step title="背书撤销"></el-step>
        <el-step title="完成"></el-step>
      </el-steps>
      <endorse-recall-one v-if="activeStep === 1" @handleSubmit="handleSubmit"></endorse-recall-one>
      <status v-else-if="activeStep === 2">
        <template v-slot:statusDescribe>
          <h3>{{text}}</h3>
          <h3 class="operation-number">操作编号：<span>{{handlerNo}}</span></h3>
          <el-button type="primary" @click="handleCopy" style="margin-top: 20px">点击复制</el-button>
        </template>
        <template v-slot:statusBtn>
          <el-button type="primary" size="medium" @click="handleSuccess">完成</el-button>
        </template>
      </status>
    </el-card>
  </div>
</template>

<script>
  /*背书撤销*/
  import EndorseRecallOne from "./EndorseRecallOne";
  /*完成*/
  import Status from "@/views/Ticket/childrenCpn/Status";
  export default {
    name: "EndorseRecallStep",
    components: {
      EndorseRecallOne,
      Status
    },
    data(){
      return {
        activeStep: 1,// 步骤条当前激活步骤
        text: '', //成功后的文本
        handlerNo: '', // 成功后的操作编号
      }
    },
    methods: {
      // 监听保存
      handleSubmit({activeStep, text, handlerNo}){
        console.log('EndorseRecallStep', activeStep, text, handlerNo)
        this.activeStep = activeStep
        this.text = text;
        this.handlerNo = handlerNo
      },
      // 完成
      handleSuccess(){
        this.$router.push({name: 'endorseRecall'})
      },
      // 点击复制
      handleCopy(){
        this.$copyText(this.handlerNo).then(res => {
          this.$message.success('复制成功')
          this.successDialogVisible = false
          return false;
        },err => {
          this.$message.error('复制失败')
          this.successDialogVisible = true
        })
      },
    }
  }
</script>

<style scoped>

</style>